<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            ref="filterModal"
        >
            <template v-slot:filters>
                <div v-show="false">
                    <TitleTypeAccountFilter
                        ref="titleTypeAccountFilter"
                        v-model:titleType="filterPayload.titleType"
                        v-model:manCodes="filterPayload.manCodes"
                        :hasSelectedALL="true"
                    />
                </div>
                <Dropdown
                    type="text"
                    :title="filterTitles.eventId"
                    :required="true"
                    :searchable="false"
                    :options="events"
                    v-model="filterPayload.eventId"
                />
                <Textbox
                    name="userName"
                    title="客戶姓名"
                    type="text"
                    v-model="filterPayload.userName"
                    placeholder="請輸入客戶姓名"
                />
                <Textbox
                    name="endDate"
                    type="date"
                    :title="filterTitles.registerDate"
                    :max="'today'"
                    min="default"
                    :required="false"
                    v-model="filterPayload.registerDate"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
import _ from 'lodash'

import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import Textbox from '@/components/Textbox.vue'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'

import { ctwEventFilterTitles } from '@/assets/javascripts/ctw/setting'
import { getEventStatusOptionsAPI } from '@/assets/javascripts/api'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'CtwUploadFilter',
    emits: [
        'show-data-table',
        'update:filterLogProp',
        'update:filterPayloadProp'
    ],
    components: {
        Dropdown,
        FilterModal,
        Textbox,
        TitleTypeAccountFilter
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCodes: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        /**
         * 重置篩選內容
         */
        resetFilter: function () {
            this.filterPayload = { ...filterDefault.ctwEventStatues }
            this.filterPayload.eventId = this.events[0].value ?? null
            this.$refs.titleTypeAccountFilter.resetFilter()
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },
        /**
         * 取得篩選結果
         */
        getFilterData: async function () {
            const isSameValue = this.$isSameValue(
                this.perviousFilterPayload,
                this.filterPayload
            )
            if (!isSameValue) {
                this.$emit('show-data-table')
                this.perviousFilterPayload = _.cloneDeep(this.filterPayload)
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
        },
        /**
         * 取得活動
         */
        getOptions: async function () {
            try {
                const response = await getEventStatusOptionsAPI()
                this.events = response.data.events.map((event) => ({
                    label: event.eventName,
                    value: event.eventId
                }))
                this.filterPayload.eventId = this.events[0].value
            } catch (error) {
                this.events = []
            }
        }
    },
    computed: {
        filterOptions() {
            return {
                eventId: this.events.filter((event) => event.label)
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            isLoading: false,
            filterPayload: this.filterPayloadProp,
            perviousFilterPayload: {},
            filterLog: this.filterLogProp,
            filterTitles: ctwEventFilterTitles,
            events: []
        }
    },
    mounted() {
        this.getOptions()
    }
}
</script>

<style lang="scss" scoped></style>
